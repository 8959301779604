<template>
  <div class="columns is-centered">
    <div class="column is-two-fifths">
      <br>
      <form @submit.prevent="login">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Connexion</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Adresse mail">
              <b-input type="email" v-model="form.email" placeholder="Votre adresse mail" maxlength="50" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Mot de passe">
              <b-input type="password" v-model="form.password" placeholder="Votre mot de passe" maxlength="30" :has-counter="false" required>
              </b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
              class="is-pulled-right"
              label="Se connecter"
              native-type="submit"
              type="is-success submit"/>
          </footer>
          <b-progress type="is-info" v-if="sending"></b-progress>
        </div>
      </form>
      <br>
      <div>
        <router-link to="/recover">Mot de passe oublié?</router-link>
        |
        <router-link to="/register">Créer un compte</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import notification from "@/mixins/notification";

export default {
  name: 'LoginForm',
  mixins: [notification],
  data: () => ({
    form: {
      email: null,
      password: null
    },
    userSaved: false,
    sending: false,
    lastUser: null
  }),
  methods: {
    login () {
      this.sending = true
      this.$store.dispatch('login', this.form)
        .then(() => {
          this.showNotification('Connexion réussie.', 'success');
          this.$router.push('/')
        })
        .catch((error) => {
          console.log(error)
          this.showError('Utilisateur ou mot de passe erroné.')
          this.sending = false
        })
    }
  }
};
</script>

<style scoped>

.modal-card {
  width: auto;
}

</style>
